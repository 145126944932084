/* ============= Import Fonts ============= */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Varela+Round');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* ==========================================================
   Global CSS - Refactored and Maintained for Consistency
   ========================================================== */

/* ============= Root Variables ============= */
:root {
  /* Colors */
  --background-primary: #0F0F0F;
  --background-secondary: #1A1A1A;
  --text-primary: #FFFFFF;
  --text-secondary: rgba(255, 255, 255, 0.7);
  
  /* Brand Colors */
  --primary-color: #FF93A4;
  --primary-color-dark: #E57C8C;
  --accent-color: #4DFFFF;
  --error-color: #FF93A4;
  
  /* RGB Values for opacity operations */
  --primary-rgb: 255, 147, 164;
  --accent-rgb: 77, 255, 255;
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-2xl: 2.5rem;
  
  /* Border Radius */
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --radius-xl: 16px;
  --radius-full: 9999px;
  
  /* Transitions */
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
  --transition-slow: 0.5s ease;
  
  /* Shadows */
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 8px rgba(0, 0, 0, 0.2);
  --shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.3);
  
  /* Typography */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-2xl: 2rem;
  --font-size-3xl: 2.5rem;
  
  /* Font Weights */
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  
  /* Z-index layers */
  --z-modal: 1000;
  --z-overlay: 900;
  --z-dropdown: 800;
  --z-header: 700;
  --z-footer: 600;
  
  /* Gradients */
  --gradient-primary: linear-gradient(to right, #E8B4B8, #F5E6E8);
  --gradient-user: linear-gradient(to right, #FFE4E1, #D8B4BE);
  --gradient-title: linear-gradient(to right, #D4A5A5, #C7989F);
  --gradient-button-1: linear-gradient(to right, #E8B4B8, #FFE4E1);
  --gradient-button-2: linear-gradient(to right, #F5E6E8, #D8B4BE);
  --gradient-button-3: linear-gradient(to right, #D8B4BE, #D4A5A5);
  --gradient-button-4: linear-gradient(to right, #FFE4E1, #E8B4B8);
  
  /* Shadows */
  --shadow-primary: 0px 6px 16px 0px rgba(232, 180, 184, 0.15), 
                   0px 4px 14px 0px rgba(232, 180, 184, 0.1), 
                   0px 8px 10px 0px rgba(232, 180, 184, 0.05);
  
  /* Typography */
  --font-family-primary: var(--font-loob, 'Comfortaa'), 'Nunito', 'Varela Round', sans-serif;
  --font-size-default: 16px;
  --font-weight-title: 700;
  
  /* Z-index */
  --z-index-modal: 1000;
  --z-index-overlay: 900;
  --z-index-dropdown: 800;
  --z-index-header: 700;
  
  /* Layout */
  --header-height: 4rem;
  --footer-height: 3rem;
  --max-content-width: 1200px;
  --side-padding: 1rem;
  
  /* Mobile viewport height (set by JS) */
  --vh: 1vh;
}

/* ============= Tailwind CSS Directives ============= */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ============= CSS Reset for Consistency ============= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html, body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family-primary);
  background-color: var(--background-primary);
  color: var(--text-primary);
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  overscroll-behavior: none;
  touch-action: manipulation;
  -webkit-overflow-scrolling: touch;
  /* Disable Safari's text size adjustment without disabling user zoom */
  -webkit-text-size-adjust: 100%;
}

/* ============= Global Styles ============= */
body {
  line-height: 1.6;
  word-wrap: break-word;
  font-family: var(--font-family-primary);
}

input,
textarea,
select,
button {
  font-family: inherit;
  font-size: var(--font-size-md);
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--radius-md);
  color: var(--text-primary);
  padding: var(--spacing-md);
  transition: var(--transition-normal);
}

input:focus,
textarea:focus,
select:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.2);
}

/* Remove default tap highlight color */
input,
textarea,
select,
button {
  -webkit-tap-highlight-color: transparent !important;
}

/* Add a custom focus ring for accessibility */
@supports selector(:focus-visible) {
  input:focus-visible,
  textarea:focus-visible,
  select:focus-visible {
    outline: none !important;
    border: double 2px transparent !important;
    border-radius: 8px !important;
    background-image: linear-gradient(#222, #222), 
                     linear-gradient(to right, #FFB3BA, #FFDAB9) !important;
    background-origin: border-box !important;
    background-clip: padding-box, border-box !important;
  }
}

/* Headers and Containers */
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: var(--background-secondary);
  box-shadow: var(--shadow-primary);
  margin: 0; /* Ensure no margin */
  padding: 0.5rem var(--side-padding); /* Standardize padding */
  height: var(--header-height); /* Ensure consistent height */
}

.footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--background-primary);
}

main {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Input Fields */
input::placeholder {
  color: var(--text-secondary);
}

@layer base {
  input {
    background-color: var(--background-soft) !important;
  }
  
  /* Add select element styling */
  select {
    background-color: var(--background-soft) !important;
    color: var(--text-primary) !important;
    border: 1px solid var(--border-primary) !important;
    border-radius: var(--border-radius-md);
    padding: var(--spacing-md);
    font-family: inherit;
    font-size: 1rem;
    transition: var(--transition-normal);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.7rem center;
    background-size: 1em;
    padding-right: 2.5rem;
  }

  /* Style select options */
  select option {
    background-color: var(--background-soft);
    color: var(--text-primary);
    padding: 0.5rem;
  }

  /* Style select when opened */
  select:focus {
    border-color: rgba(255, 255, 255, 0.2) !important;
    box-shadow: 
      0 0 0 2px rgba(255, 255, 255, 0.1),
      0 4px 6px -1px rgba(0, 0, 0, 0.1);
    outline: none;
  }

  /* Remove default select arrow in IE */
  select::-ms-expand {
    display: none;
  }

  /* Style disabled state */
  select:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  /* Add these base styles for heading elements */
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-primary);
    font-weight: var(--font-weight-bold, 700);
  }

  h1 {
    font-size: var(--font-size-3xl);
  }

  h2 {
    font-size: var(--font-size-2xl);
  }

  h3 {
    font-size: var(--font-size-xl);
  }

  h4 {
    font-size: var(--font-size-lg);
  }

  h5 {
    font-size: var(--font-size-md);
  }

  h6 {
    font-size: var(--font-size-sm);
  }
}

/* Buttons */
.base-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary);
  background: var(--gradient-primary);
  border: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: var(--transition-normal);
  height: fit-content;
  min-height: 2.75rem;
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  font-family: var(--font-family-primary);
}

.base-button:hover {
  transform: translateY(-1px);
  background: var(--gradient-user);
  box-shadow: 
    0 6px 8px -2px rgba(0, 0, 0, 0.2),
    0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.base-button:active {
  transform: translateY(0);
  box-shadow: 
    0 2px 4px -1px rgba(0, 0, 0, 0.1),
    0 1px 2px -1px rgba(0, 0, 0, 0.06);
}

.base-button:disabled {
  background: #4a4a4a;
  color: #8a8a8a;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Animations */
.dot-flashing {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: var(--text-tertiary);
  animation: dot-flashing 1s infinite linear alternate;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--text-tertiary);
  }
  50%, 100% {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  main {
    padding: 3.5rem 1rem;
  }
}

@media (max-width: 640px) {
  main {
    padding: 3rem 0.5rem;
  }
}

/* Update responsive font sizes */
@media screen and (min-width: 768px) {
  :root {
    --font-size-default: 16px;
    font-size: 16px;
  }
}

/* Add touch-friendly sizing for inputs */
input,
textarea,
button {
  min-height: 44px;
  font-size: 16px;
}

/* Add these utility classes */
.touch-target {
  min-height: 44px;
  min-width: 44px;
}

.no-tap-highlight {
  -webkit-tap-highlight-color: transparent;
}

/* Mobile-first container */
.container {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

/* Update HTML and body styles */
html {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

/* Add these near the top of your CSS file, after the imports */
html {
  touch-action: manipulation;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

body {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  width: 100%;
  height: 100%;
  overscroll-behavior-y: none;
  padding: 0; /* Remove top padding */
  margin: 0; /* Ensure no margin */
}

/* Add these new utility classes */
.no-zoom-fix {
  font-size: 16px !important; /* Prevents iOS zoom */
  touch-action: manipulation;
  -webkit-appearance: none;
  appearance: none;
}

/* Add touch optimization */
.touch-target {
  min-height: 44px;
  min-width: 44px;
  padding: 8px;
  touch-action: manipulation;
}

/* Prevent pull-to-refresh */
:root {
  overscroll-behavior-y: none;
  overflow: hidden;
}

/* Disable text selection for app-like feel */
.no-select {
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

/* Smooth scrolling */
.smooth-scroll {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

/* Add this to ensure proper sizing on iOS */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

/* Update your existing button styles */
.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-accent {
  padding: 0.75rem 1.5rem;
  color: #1a1a1a;
  border: none;
  border-radius: var(--radius-full);
  font-weight: var(--font-weight-semibold);
  transition: var(--transition-normal);
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  background-size: 200% auto;
}

.btn-primary {
  background: var(--gradient-button-1);
}

.btn-secondary {
  background: var(--gradient-button-2);
}

.btn-tertiary {
  background: var(--gradient-button-3);
}

.btn-accent {
  background: var(--gradient-button-4);
}

.btn-primary:hover,
.btn-secondary:hover,
.btn-tertiary:hover,
.btn-accent:hover {
  transform: translateY(-1px);
  filter: brightness(1.05);
  background-position: right center;
}

.btn-primary:disabled,
.btn-secondary:disabled,
.btn-tertiary:disabled,
.btn-accent:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

/* Add hover effect with pseudo-element for shine */
.btn-primary::after,
.btn-secondary::after,
.btn-tertiary::after,
.btn-accent::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  transition: all 0.3s ease;
  opacity: 0;
}

.btn-primary:hover::after,
.btn-secondary:hover::after,
.btn-tertiary:hover::after,
.btn-accent:hover::after {
  opacity: 1;
  transform: rotate(45deg) translate(50%, 50%);
}

/* Gradient title */
.gradient-title {
  background: var(--gradient-title);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-primary);
}

/* Error Boundary Styles */
.error-boundary {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  padding: var(--spacing-xl);
}

.error-boundary__content {
  text-align: center;
  max-width: 400px;
}

.error-boundary__icon {
  width: 48px;
  height: 48px;
  color: var(--error-color);
  margin-bottom: var(--spacing-md);
}

.error-boundary__title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-sm);
  color: var(--text-primary);
}

.error-boundary__message {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-lg);
}

.error-boundary__button {
  padding: var(--spacing-sm) var(--spacing-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: white;
  background: var(--error-color);
  border: none;
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: var(--transition-normal);
}

.error-boundary__button:hover {
  filter: brightness(1.1);
}

/* Modern Minimal Styles */
body {
  background-color: var(--background-primary);
  color: var(--text-primary);
  font-family: var(--font-family-primary), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Glass Effect */
.glass-effect {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

/* Modern Button Reset */
button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Modern Form Elements */
input,
select,
textarea {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--radius-md);
  color: var(--text-primary);
  padding: var(--spacing-md);
  font-size: var(--font-size-sm);
  transition: var(--transition-normal);
}

input:focus,
select:focus,
textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.2);
}

/* Modern Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--background-secondary);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Main layout container */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Main content area */
.main-content {
  flex: 1;
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
  padding: var(--spacing-xl);
  position: relative;
  overflow-y: auto;
}

@media (max-width: 768px) {
  :root {
    --side-padding: 0.75rem;
  }
  
  .main-content {
    padding: var(--spacing-md);
  }
}

/* Add these layout styles near the top after your variables */
.app-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: var(--background-primary);
  margin: 0;
  padding: 0;
  transform: translateZ(0); /* Force hardware acceleration */
  backface-visibility: hidden; /* Performance optimization */
  -webkit-font-smoothing: antialiased;
}

.app-content {
  flex: 1;
  position: relative;
  height: calc(100vh - var(--header-height) - var(--footer-height));
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  transform: translateZ(0); /* Force hardware acceleration */
  z-index: 1;
}

.content-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  z-index: 1;
}

/* Full-screen component handling */
.full-screen-component {
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: var(--footer-height);
  z-index: 20;
  background: var(--background-primary);
  overflow-y: auto;
}

/* Modal component handling */
.modal-component {
  position: fixed;
  top: var(--header-height);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: var(--max-content-width);
  height: calc(100vh - var(--header-height) - var(--footer-height));
  z-index: 30;
  background: var(--background-primary);
  border-radius: 1rem;
  overflow: hidden;
}

@media (max-width: 768px) {
  .modal-component {
    top: var(--header-height);
    left: 0;
    transform: none;
    border-radius: 0;
    max-width: none;
  }
}

/* Remove tap highlight on mobile */
* {
  -webkit-tap-highlight-color: transparent;
}

/* Basic form element styling */
input,
textarea,
select,
button {
  font-family: inherit;
}

/* Remove focus outlines for mouse users, keep for keyboard */
:focus:not(:focus-visible) {
  outline: none;
}

/* Keep focus indicators for keyboard users */
:focus-visible {
  outline: 2px solid #fff;
  outline-offset: 2px;
}

/* Mobile-specific styles - Enhanced version */
@media (max-width: 767px) {
  .hidden-on-mobile {
    display: none !important;
  }
  
  /* Adjust main padding for mobile layout */
  main {
    padding-top: 1rem !important;
    padding-bottom: calc(var(--footer-height) + 1rem) !important;
    min-height: calc(var(--vh, 1vh) * 100 - var(--footer-height)) !important;
    height: auto !important;
  }
  
  /* Force header to bottom on mobile */
  .header-container {
    position: fixed !important;
    top: auto !important;
    bottom: 0 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-bottom: none !important;
    height: var(--footer-height, 4rem) !important;
    padding: 0.5rem var(--side-padding) !important;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1) !important;
    width: 100% !important;
    z-index: var(--z-index-header) !important;
  }
  
  /* Adjust body padding for bottom header instead of top */
  body {
    padding-top: 0 !important;
    padding-bottom: var(--footer-height, 4rem) !important;
    min-height: 100% !important;
    height: auto !important;
    overflow-x: hidden !important;
  }
  
  /* Adjust main container spacing */
  .main-container,
  .modal,
  .app-content,
  .content-container {
    margin-top: 0 !important;
    margin-bottom: var(--footer-height, 4rem) !important;
    padding-bottom: var(--footer-height, 4rem) !important;
    min-height: calc(var(--vh, 1vh) * 100 - var(--footer-height)) !important;
  }
}

/* Mobile view class styles (for testing) */
.mobile-view .hidden-on-mobile {
  display: none !important;
}

.mobile-view main {
  padding-top: 1rem !important;
  padding-bottom: calc(var(--footer-height) + 1rem) !important;
  min-height: calc(var(--vh, 1vh) * 100 - var(--footer-height)) !important;
  height: auto !important;
}

.mobile-view .header-container {
  position: fixed !important;
  top: auto !important;
  bottom: 0 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-bottom: none !important;
  height: var(--footer-height, 4rem) !important;
  padding: 0.5rem var(--side-padding) !important;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1) !important;
  width: 100% !important;
  z-index: var(--z-index-header) !important;
}

.mobile-view body {
  padding-top: 0 !important;
  padding-bottom: var(--footer-height, 4rem) !important;
  min-height: 100% !important;
  height: auto !important;
  overflow-x: hidden !important;
}

.mobile-view .main-container,
.mobile-view .modal,
.mobile-view .app-content,
.mobile-view .content-container {
  margin-top: 0 !important;
  margin-bottom: var(--footer-height, 4rem) !important;
  padding-bottom: var(--footer-height, 4rem) !important;
  min-height: calc(var(--vh, 1vh) * 100 - var(--footer-height)) !important;
}

/* Mobile layout class for forced testing */
.mobile-layout .header-container {
  position: fixed !important;
  top: auto !important;
  bottom: 0 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-bottom: none !important;
  height: var(--footer-height, 4rem) !important;
  padding: 0.5rem var(--side-padding) !important;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1) !important;
}

.mobile-view .footer-container,
.mobile-layout .footer-container {
  display: none !important;
}

/* Mobile adjustments for app content */
@media (max-width: 767px) {
  .app-content {
    height: calc(var(--vh, 1vh) * 100 - var(--footer-height)) !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .content-container {
    padding-bottom: var(--footer-height) !important;
    min-height: calc(var(--vh, 1vh) * 100 - var(--footer-height)) !important;
  }
}

/* Mobile view class adjustments for app content */
.mobile-view .app-content {
  height: calc(var(--vh, 1vh) * 100 - var(--footer-height)) !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.mobile-view .content-container {
  padding-bottom: var(--footer-height) !important;
  min-height: calc(var(--vh, 1vh) * 100 - var(--footer-height)) !important;
}

/* Add this to the end of your CSS file to handle mobile viewport height */
:root {
  --vh: 1vh;
}

/* This should be accompanied by JavaScript to set the correct viewport height:
   document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
   window.addEventListener('resize', () => {
     document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
   });
*/

/* Dark Mode Optimizations */
@media (prefers-color-scheme: dark) {
  :root {
    --background-primary: #0F0F0F;
    --background-secondary: #1A1A1A;
    --text-primary: #FFFFFF;
    --text-secondary: rgba(255, 255, 255, 0.7);
  }
}

/* Add these rules to handle mobile viewport and prevent scrolling issues */
@media screen and (max-width: 767px) {
  :root {
    --vh: 1vh;
  }
  
  html, body {
    height: 100vh; /* Fallback */
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden !important; /* Only prevent horizontal scrolling by default */
    position: relative; /* Changed from fixed to allow scrolling */
    width: 100%;
  }
  
  #__next, .app-container, .app-layout {
    height: 100vh; /* Fallback */
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden; /* Only prevent horizontal scrolling */
  }
  
  /* Explicitly disable all scrolling for chat component */
  .chat-container, 
  .no-scroll {
    overflow: hidden !important;
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    touch-action: none !important; /* Disable pull-to-refresh */
  }
  
  /* Allow scrolling for components that need it */
  .scroll-enabled {
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
    touch-action: pan-y !important;
  }
  
  main {
    height: calc(100vh - var(--footer-height, 4rem) - env(safe-area-inset-bottom, 20px));
    max-height: calc(100vh - var(--footer-height, 4rem) - env(safe-area-inset-bottom, 20px));
    position: relative;
    /* Allow scrolling by default */
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  
  /* Ensure content stays within viewport but allow scrolling by default */
  .app-content, .content-container {
    height: 100%;
    max-height: calc(100vh - var(--footer-height, 4rem) - env(safe-area-inset-bottom, 20px));
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(var(--footer-height, 4rem) + env(safe-area-inset-bottom, 20px)) !important;
  }
  
  /* Components that need scrolling */
  .profile-container,
  .add-entry-container,
  .loobricate-profile-container {
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
    padding-bottom: calc(var(--footer-height, 4rem) + env(safe-area-inset-bottom, 20px)) !important;
    height: 100% !important;
  }
  
  /* Add safe area insets for iOS */
  .pb-safe-area {
    padding-bottom: env(safe-area-inset-bottom, 20px) !important;
  }
  
  /* Fix for iOS Safari sticky position */
  .ios-fix {
    transform: translateZ(0);
  }
  
  /* Ensure bottom navigation is always visible */
  nav.fixed-bottom,
  .header-container {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 9999 !important;
    background-color: var(--background-primary, #0F0F0F) !important;
    backdrop-filter: blur(8px) !important;
    -webkit-backdrop-filter: blur(8px) !important;
    padding-bottom: calc(0.5rem + env(safe-area-inset-bottom, 20px)) !important;
    margin-bottom: env(safe-area-inset-bottom, 0px) !important;
  }
}

/* Fix for notched devices like iPhone X and newer */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .safe-bottom {
    padding-bottom: env(safe-area-inset-bottom);
  }
  
  .fixed-bottom {
    bottom: env(safe-area-inset-bottom);
  }
}

/* Prevents rubber-banding/bounce scrolling effects on touch devices */
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Allow vertical scrolling */
  position: relative; /* Change from fixed to relative */
  overscroll-behavior: none;
  touch-action: manipulation; /* Allow more interactions */
  -webkit-overflow-scrolling: touch;
  /* Disable Safari's text size adjustment without disabling user zoom */
  -webkit-text-size-adjust: 100%;
}

/* Override iOS touch and tap highlighting */
* {
  -webkit-tap-highlight-color: transparent;
  /* Don't prevent selection globally */
  /* user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; */
}

/* Make text selectable where needed */
p, h1, h2, h3, h4, h5, h6, span, div.selectable, pre, code {
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}

/* Smooth scrolling for all scrollable containers */
.scrollable-container {
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  /* Use the --vh variable to get accurate height on mobile */
  max-height: calc(var(--vh, 1vh) * 100);
}

/* Fix for iOS safari viewport height issues */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: calc(var(--vh, 1vh) * 100);
  }
}

/* PWA specific optimizations */
@media (display-mode: standalone) {
  /* Adjust UI when running as installed PWA */
  body {
    /* Add padding for safe areas on notched devices */
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  
  .safe-area-bottom {
    padding-bottom: env(safe-area-inset-bottom);
  }
  
  .safe-area-top {
    padding-top: env(safe-area-inset-top);
  }
}

/* Touch-friendly interactive elements */
.touch-target {
  min-width: 44px;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Make interactive elements explicitly clickable */
a, button, input, select, textarea, [role="button"], [tabindex]:not([tabindex="-1"]) {
  cursor: pointer;
  pointer-events: auto !important; /* Ensure clicks work */
  touch-action: manipulation;
}

/* ============= Modal Styling for Cross-Platform Compatibility ============= */
.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: var(--z-modal);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 1rem;
  touch-action: none;
}

.modal-content {
  position: relative;
  background-color: var(--background-secondary);
  border-radius: var(--radius-lg);
  max-width: 90vw;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: var(--shadow-lg);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: auto;
  overscroll-behavior: contain;
  transform: translateZ(0); /* Force hardware acceleration */
  will-change: transform; /* Optimize for animations */
}

.modal-header {
  position: sticky;
  top: 0;
  background-color: var(--background-secondary);
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.modal-body {
  padding: 1rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modal-footer {
  position: sticky;
  bottom: 0;
  background-color: var(--background-secondary);
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  z-index: 1;
}

.modal-close-button {
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--text-secondary);
  transition: all 0.2s ease;
}

.modal-close-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--text-primary);
}

/* Prevent scrolling when modal is open */
body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

/* Mobile-specific modal adjustments */
@media (max-width: 640px) {
  .modal-content {
    max-width: 100vw;
    max-height: 100vh;
    border-radius: var(--radius-md);
    margin: 0;
  }
  
  .modal-overlay {
    padding: 0.5rem;
  }
}

/* Screen reader only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

body {
  line-height: 1.6;
  word-wrap: break-word;
  font-family: var(--font-family-primary);
}
